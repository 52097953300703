import { Form } from "react-bootstrap";
import "./ConfirmSubscription.css";
import { useTranslation } from "react-i18next";

const packFrequencyMap = new Map([
    ["daily", 107],
    ["weekly", 108]
]);

const ConfirmSubscription = ({ formik, selectedPack, changeStep }) => {
    const { t } = useTranslation();

    function getConfirmationString(pack) {
        if (!pack) {
            return;
        }

        const amount = Math.round(pack.pack_price);
        const frequency = t(packFrequencyMap.get(pack.pack_frequency))?.toLowerCase();

        return `${t('114')}`+` ${Number.parseInt(amount)} ${pack.pack_currency}/`+ `${pack.pack_frequency == "daily" ? "day" : "week"} `+ `${t('115')}`;
    }

    return (
        <>
            <div className="login-thumbnail2">
                <img src="/image/login_img.png" alt="login" />
            </div>

            <Form className="confirmation" onSubmit={formik.handleSubmit}>
                <h5 className="mx-auto my-3 text-center"><p className="mx-auto my-0 text-center">
                    {getConfirmationString(selectedPack)}</p>
                </h5>
                <h5 className="mx-auto my-3 text-center"><p className="mx-auto my-0 text-center">
                    {t('116')}</p>
                </h5>
                <div className="button-container">
                    <button className="btn btn-primary d-block mx-auto fw-bold" type="button" onClick={() => changeStep(-1)}>
                        {t('98')}
                    </button>
                    <button className="btn btn-success d-block mx-auto fw-bold" style={{color: "white"}} type="submit">
                        {t('78')}
                    </button>
                </div>
            </Form>
        </>
    )
}

export default ConfirmSubscription;
